import { useState } from "react";

export const NavBar = (user) => {

    const [hide, setHide] = useState("hidden");
  
    const changeHidden = () => {
      hide === "hidden" ? setHide(" ") : setHide("hidden");
    }
    
    return (
      <nav className="absolute w-full">
        <div className="mx-8 my-1 px-1 py-1">
  
          <div className="flex justify-between">
  
            <div className="flex space-x-4">
              <div>
                <a href="" class="flex items-center py-5 px-2">
                  <svg className= "h-7 w-7 mr-1 text-[#64ffda]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 7.5l3 2.25-3 2.25m4.5 0h3m-9 8.25h13.5A2.25 2.25 0 0021 18V6a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 6v12a2.25 2.25 0 002.25 2.25z" />
                  </svg>
                  <span className="font-bold font-baloo-bhai text-2xl ml-1 text-[#E8EEF2]">{user.name}</span>
                </a>
              </div>
  
              
            </div>
            
            <div className="hidden md:flex items-center space-x-12 font-baloo-bhai text-lg">
              <div className="hidden md:flex items-center space-x-6 font-baloo-bhai text-lg">
                  <a href="#about" className="py-5 px-3 text-gray-200 hover:text-[#64ffda]"><span className="mr-2 text-[#64ffda]">01. </span>About</a>
                  <a href="#experience" className="py-5 px-3 text-gray-200 hover:text-[#64ffda]"><span className="mr-2 text-[#64ffda]">02. </span>Experience</a>
                  <a href="#projects" className="py-5 px-3 text-gray-200 hover:text-[#64ffda]"><span className="mr-2 text-[#64ffda]">03. </span>Projects</a>
                </div>
              <button className="border border-[#64ffda] rounded-md p-2 m-4 text-xl font-baloo-bhai text-[#64ffda]"><a href="#contact">Contact</a></button>
            </div>
  
            <div className="md:hidden flex items-center">
              <button onClick={changeHidden}>
                <svg className="w-6 h-6 text-[#64ffda]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                </svg>
              </button>
            
  
            </div>
  
          </div>     
        </div>
   
        <div className= {`${hide} h-16 md:hidden font-baloo-bhai md:h-16`}>
            <a href="#about" className="block py-2 px-4 text-md text-[#64ffda]">About</a>
            <a href="#experience" className="block py-2 px-4 text-md text-[#64ffda]">Experience</a>
            <a href="#projects" className="block py-2 px-4 text-md text-[#64ffda]">Projects</a>
        </div>
  
      </nav>
    );
    
  }