import * as React from "react";
import CenteredTabs from "./CenteredTabs.js";
import SkillsChart from "./SkillsChart.js";
import Resume from "./RuanWen_CV_24.pdf";


export const Experience = () => {

    return(
        <div id="experience" className="container mx-auto py-12 mt-24 text-[#FAFAFA]">
            <h1 className="font-ubuntu text-3xl">Experience</h1>
            <div className="flex flex-wrap justify-center pt-12">

                <div className="flex-initial w-[30rem] h-96">
                    <SkillsChart/>
                </div>

                <div className="flex-initial w-[36rem] h-96">
                    <CenteredTabs/>
                </div>
            </div>

            <div className="mt-7">
                <button className="border border-[#64ffda] rounded-md p-2 text-xl font-baloo-bhai text-[#64ffda]"><a href={Resume} target='_blank' rel='noopener noreferrer'>View Resume</a></button>
            </div>

            <div className="pt-12 italic text-2xl text-[#d7d8d6be]">Tell me and I forget. Teach me and I remember. Involve me and I learn. -Benjamin Franklin</div>
            
        </div>
        
    );
}

