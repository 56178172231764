import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import GitHubIcon from '@mui/icons-material/GitHub';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { fontFamily } from '@mui/system';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function RecipeReviewCard(props) {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card sx={{ maxWidth: 345, backgroundColor: "#011936aa", color: "#64ffdb", fontFamily: "'Baloo Bhai 2', 'cursive'", border: "solid 1px", borderRadius: "8px"}}>
      <CardHeader
        title= {props.title}
        titleTypographyProps={{fontFamily: "'Ubuntu', 'sans-serif'", fontSize: "1.5rem"}}
      />
      <CardMedia
        component="img"
        height="150"
        image={props.imgurl}
        alt={props.alttext}
        sx={{padding: "1rem", height: 160, objectFit: "contain"}}
      />
      <CardContent>
        <Typography variant="body2" color="#fafafa" fontFamily="'Baloo Bhai 2', 'cursive'" fontSize={"1.1rem"}>
          {props.description}
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <IconButton aria-label="go to github" href={props.link} target="_blank">
          <GitHubIcon 
          sx={{color: "#64ffdb"}}
          />
        </IconButton>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon 
          sx={{color: "#64ffdb"}}
          />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography paragraph color="#fafafa" fontFamily="'Baloo Bhai 2', 'cursive'">Tools and Languages:</Typography>
          <Typography paragraph color="#fafafa" fontFamily="'Baloo Bhai 2', 'cursive'" textAlign="left">
            {props.tools.map(tool => <li>{tool}</li>)}
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
}