
import './App.css';
import {Intro} from "./Intro.js"
import {NavBar} from "./NavBar.js";
import {Hero} from "./Hero.js"
import CenteredTabs, { Experience } from './Experience';
import {Projects} from './Projects';
import Lottie from "lottie-react";
import blockBounce from "./136466-mevo-perdidex.json";
import gameLoading from "./gameLoading.json";
import { useEffect, useState } from "react";
import BasicSpeedDial from './SideBar';
import { Footer } from './Footer';
import { ContactMe } from './ContactMe';

function App() {

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    },2400)
  },[])

  return (

    <div className="App bg-[#011936f3] overflow-hidden">

      {
          loading ?
          <div className=" bg-[#011936bb] flex flex-col mx-auto min-h-screen max-h-screen justify-center content-center">
            <div className="flex justify-center align-center max-h-48">
              <Lottie animationData={gameLoading} loop={true}></Lottie>
            </div>
          </div>
          

          :
          <div className="w-full h-full">
            <div className="bottom-48 right-0 rotate-90 translate-x-24 text-sm text-[#d7d8d6be] hover:text-[#64ffda] hover:translate-y-1 hover:scale-105 fixed hidden md:flex">
              <a href="mailto:wruan0909@gmail.com" className="tracking-[.25rem]">wruan0909@gmail.com //////////////</a>
              
            </div>
            <NavBar name="Wen Le Ruan"/>
            <Intro></Intro>
            <Hero/>
            <Experience/>
            <Projects/>
            <ContactMe/>
            <Footer/>
          </div>
          

      }


    </div>
    
  );
}

export default App;
