import RecipeReviewCard from "./ProjectCard"
import AVKImage from "./AVKhydrantApp.PNG"
import SolvedokuImage from "./SolvedokuApp3.PNG"
import PortfolioImage from "./portfolio1.PNG"
import JazmineImage from "./jazmineweb.PNG"

export const Projects = () => {

    return(
        <div id="projects" className="container mx-auto font-ubuntu my-16 pt-8">
            <h1 className="pt-4 text-3xl font-baloo-bhai text-[#FAFAFA]">Projects</h1>

            <div className="flex flex-wrap pt-8 justify-center">
                <div className="mb-2 mx-2 hover:-translate-y-3 contrast-125 hover:contrast-100">
                    <RecipeReviewCard 
                    title="Hydrant Detection App"
                    description="This android application uses an AI trained model to identify fire hydrants and return proper documentation on identified hydrant from company directories"
                    imgurl= {AVKImage}
                    alttext="Screenshots of AVK app"
                    tools={["Tensorflow & Tensorflow Lite", "Java and Android SDK", "Python", "MongoDB"]}
                    link={"https://github.com/WenRuan/AmericanAVK"}
                    />
                </div>
                <div className="mb-2 mx-2 hover:-translate-y-3 contrast-125 hover:contrast-100">
                    <RecipeReviewCard 
                    title="Sudoku Game and Solver"
                    description="A sudoku game created with Pygame to learn basic game logic. Has a feature that uses a backtracing algorithm to solve sudoku boards with animated steps"
                    imgurl={SolvedokuImage}
                    alttext="Sudoku Game App"
                    tools={["Pygame Engine", "Python", "Backtracing"]}
                    link={"https://github.com/WenRuan/Solvedoku"}
                    />
                </div>
            </div>

            <div className="flex flex-wrap pt-2 justify-center">
                <div className="mb-2 mx-2 hover:-translate-y-3 contrast-125 hover:contrast-100">
                    <RecipeReviewCard 
                    title="Web Portfolio V1"
                    description="Original web portfolio before this current version. Made during free time when learning new languages and tools. Used to learn basics of Html, CSS, and Javascript."
                    imgurl={PortfolioImage}
                    alttext="V1 of Web Portfolio"
                    tools={["HTML", "CSS / SASS", "Javascript, NodeJs", "Bootstrap"]}
                    link={"https://github.com/WenRuan/Resume-Site"}
                    />
                </div>
                <div className="mb-2 mx-2 hover:-translate-y-3 contrast-125 hover:contrast-100">
                    <RecipeReviewCard 
                    title="Restaurant Website"
                    description="Website created for family chinese and sushi restaurant business in Reno, Nevada. Still active and used to post events, offers and updates for customers."
                    imgurl={JazmineImage}
                    alttext="Website for Jazmine Restaurant"
                    tools={["HTML", "CSS", "Javascript, NodeJs"]}
                    link={"http://www.jazminereno.com/"}
                    />
                </div>
            </div>

            <div className="text-2xl text-[#64ffda] pt-8">More Coming Soon!</div>
            
        </div>
    );


}