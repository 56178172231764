import { useState } from "react";

export const ContactMe = () => {

    const [name, setName] = useState("Your Name");
    const [email, setEmail] = useState("Your Email");
    const [message, setMessage] = useState("Your Message");

    const updateName = (event) => {
        setName(event.target.value);
    }

    const updateEmail = (event) => {
        setEmail(event.target.value);
    }

    const updateMessage = (event) => {
        setMessage(event.target.value);
    }

    return(

        <div id="contact" className="container mx-auto mt-32 py-16 font-baloo-bhai">
            <h1 className="font-baloo-bhai text-3xl text-[#FAFAFA]">Contact</h1>
            <div className="mt-8 text-[#64ffda] text-2xl">Lets get in touch!</div>
            <div className="text-xl text-[#d7d8d6be] mx-auto max-w-md"><p>I'm openly looking for new opportunities. Feel free to send me an email or submit a form and I'll get back to you as soon as I can! </p></div>
           
            <form name="contact" method="post" className="pt-10">
                <input type="hidden" name="form-name" value="contact" />
                <div className="flex flex-wrap justify-center">
                    <div className="mx-4">
                        <input type="text" id="name" name="name" onChange={updateName} value={name} className="mt-10 h-8 rounded-md border-2 border-[#d7d8d6be] focus:outline-none focus:ring-2 focus:ring-[#64ffda] caret-[#64ffda]"/><br/>
                        <input type="email" id="email" name="email" onChange={updateEmail} value={email} className="mt-6 h-8 rounded-md border-2 border-[#d7d8d6be] focus:outline-none focus:ring-2 focus:ring-[#64ffda] caret-[#64ffda]"/><br/>
                    </div>

                    <div className="mx-4">
                        <textarea type="text" id="message" name="message" onChange={updateMessage} value={message} className="mt-10 h-24 w-96 rounded-md border-2 border-[#d7d8d6be] focus:outline-none focus:ring-2 focus:ring-[#64ffda] caret-[#64ffda]"/><br/>
                    </div>
                </div>
                
                <div className="mt-4">
                    <button type="submit" className="border border-[#64ffda] rounded-md p-4 m-4 text-xl font-baloo-bhai text-[#64ffda]">Submit</button>
                </div>
                
            </form>
        </div>
    );

}