import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} variant="scrollable" aria-label="Work Experience" sx={{ 
            borderRight: 1, 
            borderColor: 'divider', 
            '& .MuiTabs-indicator':{backgroundColor: '#64FFDA'},
            '& .MuiTab-root': { color: '#64FFDA' },
            '& .Mui-selected': { color: '#64FFDA' }
        }}>
          <Tab label="Web Developer Intern" {...a11yProps(0)} />
          <Tab label="Software Eng Intern" {...a11yProps(1)} />
          <Tab label="Project Management Intern" {...a11yProps(2)} />
          <Tab label="Graduate Assistant" {...a11yProps(3)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <div className="font-baloo-bhai w-full">
          <h1 className="font-ubuntu text-lg pb-1">Web Development Intern</h1>
          <h3 className="font-ubuntu text-lg pb-2 italic text-[#d7d8d6be]">UNR Marketing and Communications</h3>
          <p>•	Redesigned and developed the school’s financial cost calculator with a fully dynamic approach while following ADA’s accessibility guidelines to reduce the amount of system maintenance and increased accessibility for users.</p>
          <p>•	Collaborated with cross-functional teams to implemented new tools and features for editors and content creators using .NET framework, leading to a decrease in maintenance required from developers.</p>
          <p>•	Provided web help support and performed accessibility checks for all the university’s departments.</p>
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div className="font-baloo-bhai w-full">
          <h1 className="font-ubuntu text-lg pb-1">Software Intern</h1>
          <h3 className="font-ubuntu text-lg pb-2 italic text-[#d7d8d6be]">American AVK</h3>
          <p>•	Designed, developed and tested a vision-based object recognition application to identify specific company products.</p>
          <p>•	Contributed to the decrease of the company’s paper footprint by developing an AR mobile application to store and retrieve maintenance manuals using the android development toolkit. </p>
          <p>•	Created a ML model using TensorFlow to train visual application on recognizing company products.</p>        
        </div>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <div className="font-baloo-bhai w-full">
          <h1 className="font-ubuntu text-lg pb-1">IT Project Analyst Intern</h1>
          <h3 className="font-ubuntu text-lg pb-2 italic text-[#d7d8d6be]">Nutrient</h3>
          <p>•	Implemented and managed HubSpot CRM for marketing and sales team leading to earlier customer engagement.</p>
          <p>•	Increased company structure by implementing documentation tools, data analysis tools, and SAP business applications.</p>
          <p>•	Created visual dashboards helping drive business decision making for the C-suite executives.</p>        
        </div>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <div className="font-baloo-bhai w-full">
          <h1 className="font-ubuntu text-lg pb-1">Graduate Assistant</h1>
          <h3 className="font-ubuntu text-lg pb-2 italic text-[#d7d8d6be]">UNR College of Business</h3>
          <p>•	Conducted literature searches and research.</p>
          <p>•	SAS programming and data analysis.</p>
          <p>•	Graded papers and proctored exams.</p>    
        </div>
      </TabPanel>
    </Box>
  );
}