import React, { PureComponent } from 'react';
import { Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer } from 'recharts';

const data = [
  {
    subject: 'Python',
    A: 80,
    B: 80,
    fullMark: 100,
  },
  {
    subject: 'Unity',
    A: 70,
    B: 65,
    fullMark: 100,
  },
  {
    subject: 'SQL',
    A: 95,
    B: 67,
    fullMark: 100,
  },
  {
    subject: 'Algorithms',
    A: 60,
    B: 69,
    fullMark: 100,
  },
  {
    subject: 'Visualization',
    A: 85,
    B: 35,
    fullMark: 100,
  },
  {
    subject: 'React',
    A: 70,
    B: 80,
    fullMark: 100,
  },
  {
    subject: 'C#',
    A: 80,
    B: 80,
    fullMark: 100,
  },
  {
    subject: 'Javascript',
    A: 60,
    B: 80,
    fullMark: 100,
  }
];

export default class SkillsChart extends PureComponent {

  render() {
    return (
      <ResponsiveContainer width="100%" height="100%">
        <RadarChart cx="50%" cy="50%" outerRadius="75%" data={data}>
          <PolarGrid />
          <PolarAngleAxis dataKey="subject" />
          <PolarRadiusAxis />
          <Radar name="Skills" dataKey="A" stroke="#64ffda" fill="#64ffda" fillOpacity={0.6} />
        </RadarChart>
      </ResponsiveContainer>
    );
  }
}
