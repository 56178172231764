
export const Intro = () => {
    return(
        <div className="hero container flex justify-content content-center h-[100vh] px-2 sm:px-12 mx-auto md:px-36 lg:px-60 ">

            <section className="flex flex-col justify-center text-left">
                <div className="text-[#E8EEF2]">
                    <div><h1 className="text-md sm:text-xl lg:text-2xl font-baloo-bhai ml-1 mb-5 tracking-wide text-[#64ffda]">Hi, my name is</h1></div>
                    <div><h2 className="text-3xl sm:text-5xl lg:text-6xl font-ubuntu mb-3 font-extrabold">Wen Le Ruan.</h2></div>
                    <div><h3 className="text-3xl sm:text-5xl lg:text-6xl font-ubuntu mb-5 text-[#d7d8d6be] font-extrabold">I have an itch to analyze, build, and develop.</h3></div>
                    <div className="flex text-sm sm:text-lg md:text-xl lg:text-xl">
                        <p className="text-lg sm:text-2xl lg:text-2xl basis-1/2 font-baloo-bhai mt-3 text-[#d7d8d6be]">I'm a software and data engineer with experience in full-stack development and data analytics.</p>
                    </div>
                   <div className="mt-7">
                    <button className="border border-[#64ffda] rounded-md p-2 text-xl font-baloo-bhai text-[#64ffda]"><a href="#contact">Get in touch!</a></button>
                    </div>
                </div>


            </section>
            
        </div>
    )
}
 