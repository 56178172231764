import { useState } from "react";
import { TypeAnimation } from 'react-type-animation';
import shutDown from './BLACKPINK_-_Shut_Down_(logo).png';
import blackPink from './Blackpink-Logo.png';


//Hero Component - Coding Terminal Simulation
export const Hero = () => {

    const [introStatus, setIntroStatus] = useState("unfinished");

    const ShowIntro = (props) => {
        if(introStatus === "complete"){
            return (
                    <h1>{props.text}</h1>
            )
        }
    }

    const [educStatus, setEducStatus] = useState("unfinished");

    const ShowEducation = () => {
        if(educStatus === "complete"){
            return(
                <div>
                    <h1>>> University of Nevada, Reno</h1>
                    <h1 className="pb-2">B.S. Computer Science and Engineering</h1>
                    <h1>>> University of Nevada, Reno</h1>
                    <h1 className="pb-2">MBA Business Administration</h1>
                    <h1>>> Google Advanced Data Analytics Certification</h1>
                </div>
            )
        }
    }

    const [originStatus, setOriginStatus] = useState("unfinished");

    const ShowOrigin = () => {
        if(originStatus === "complete"){
            return(
                <div>
                    <h1>>> Guangzhou China -------> Reno, Nevada</h1>
                    <h1>Reno, Nevada -------> Anywhere in the world</h1>
                </div>
            )
        }
    }

    const [skillStatus, setSkillStatus] = useState("unfinished");

    const ShowSkill = () => {
        if(skillStatus === "complete"){
            return(
                <div>
                    <h1>Languages: C#, Python, JavaScript, SQL, HTML5, CSS</h1>
                    <h1>Frameworks/Tools: React, SSMS, .NET Framework, Unity, Tableau, PowerBI</h1>
                    <h1>Libraries: Numpy, pandas, matplotlib</h1>
                </div>
            )
        }
    }

    const [hobbiesStatus, setHobbiesStatus] = useState("unfinished");

    const ShowHobbies = () => {
        if(hobbiesStatus === "complete"){
            return(
                <div>
                    <h1>>> Tennis, Gaming, Digital Art, and Traveling</h1>
                    <h1>Also, I like to develop video games</h1>
                </div>
            )
        }
    }

    const ShowCursor = () => {
        if(hobbiesStatus === "complete"){
            return(
                <TypeAnimation sequence={[1000,"(base) WenRuan:~$", 1000, "(base) WenRuan:~$ Wen.Family?", 1000, "(base) WenRuan:~$ Wen.Games?", 1000, "(base) WenRuan:~$ Wen.DoYouEvenLiftBro?", 1000]} speed={60} wrapper="h1" cursor={true} repeat={Infinity} className="text-[#64ffda]"/>
            )
        }
    }

    const [hide1, setHide1] = useState("hidden");
    const [hide2, setHide2] = useState("");

    const changeHidden = () => {
        hide1 === "hidden" ? setHide1(" ") : setHide1("hidden");
        hide2 === "hidden" ? setHide2(" ") : setHide2("hidden");
    }

    //HTML and JSX Return
    return(
        <div id="about" className="container mx-auto font-baloo-bhai text-md px-40 h-[85vh] pt-[7rem] xs:px-0 sm:px-0 align-center justify-center md:h-[88vh] lg:h-[82vh]">

            <div className="quickNav bg-[#E8EEF2] py-1 rounded-sm flex justify-between px-2">
                <h2 className="py-0 ">Terminal</h2>
                <h2 className="py-0 hidden md:flex font-bold text-xl">About Me</h2>
                <div className="flex">
                    <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15" />
                    </svg>

                    <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15" />
                    </svg>

                    <button onClick={changeHidden}>
                        <svg className="animate-bounce w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M5.636 5.636a9 9 0 1012.728 0M12 3v9" />
                        </svg>
                    </button>

                </div>
                

            </div>
            <div className={`${hide1} bg-neutral-900 rounded-sm sm:h-5/6 md:h-5/6 lg:h-5/6`}>
                {/* <div className="h-5/6 bg-local bg-top" style={{backgroundImage: `url(${blackPink})`, opacity:".2",overflow: "hidden", backgroundSize: "contain",backgroundRepeat: "no-repeat", height: "100vh"}}>
                    <img className="mx-auto pt-5" style={{opacity:"1"}} src={shutDown}/>
                </div> */}
                <img className={`sm:max-lg:hidden ${hide1} mx-auto pt-5`} src={shutDown} alt="BlackPink's Shut Down Logo"/>
                <img className={`sm:max-lg:hidden ${hide1} w-48 h-48`} src={blackPink} alt="BlackPink's basic pink and black logo"/>
            </div>
            <div className={`${hide2} hover:shadow-2xl terminal bg-neutral-900 text-gray-100 text-left mb-4 pl-2 pt-2 rounded-sm sm:h-5/6 md:h-5/6 lg:h-5/6`}>
                <div className="pb-2">
                    <TypeAnimation sequence={[1000, () => {setIntroStatus("unfinished")}, '(base) WenRuan:~$ Wen.Introduction', 1000, () => {setIntroStatus("complete")}]} speed={60} wrapper="h1" cursor={false} className="text-[#64ffda]"/>
                    <ShowIntro text=">> Hi there, I'm Wen."/>
                    <ShowIntro text="I'm currently based in Las Vegas, Nevada."/>
                </div>

                <div className="pb-2">
                    <TypeAnimation sequence={[4200, () => {setEducStatus("unfinished")}, '(base) WenRuan:~$ Wen.Education', 1000, () => {setEducStatus("complete")}]} speed={60} wrapper="h1" cursor={false} className="text-[#64ffda]"/>
                    <ShowEducation/>
                </div>

                <div className="pb-2">
                    <TypeAnimation sequence={[7400, () => {setOriginStatus("unfinished")}, '(base) WenRuan:~$ Wen.Origin', 1000, () => {setOriginStatus("complete")}]} speed={60} wrapper="h1" cursor={false} className="text-[#64ffda]"/>
                    <ShowOrigin/>
                </div>

                <div className="pb-2">
                    <TypeAnimation sequence={[10600, () => {setSkillStatus("unfinished")}, '(base) WenRuan:~$ Wen.Skills', 1000, () => {setSkillStatus("complete")}]} speed={60} wrapper="h1" cursor={false} className="text-[#64ffda]"/>
                    <ShowSkill/>
                </div>

                <div className="pb-2">
                    <TypeAnimation sequence={[13800, () => {setHobbiesStatus("unfinished")}, '(base) WenRuan:~$ Wen.Hobbies', 1000, () => {setHobbiesStatus("complete")}]} speed={60} wrapper="h1" cursor={false} className="text-[#64ffda]"/>
                    <ShowHobbies/>
                </div>

                <div className="pb-2">
                    <ShowCursor/>
                </div>


            </div>
            
        </div>
     

        
    )
    
}
